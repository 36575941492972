<template>
    <td :class="className">
        <div :class="{ gridLink : editor }"
             @click="clickHandler">
            <template v-if="displayValue">
                <span v-if="!imageValue"
                      class="circleShape">
                    {{ displayValue | nameInitials }}
                </span>
                <span v-else="imageValue"
                      class="circleShape">
                    <img :src="imageValue">
                </span>
                <span class="cricle-displayname gridLink">
                    {{ displayValue }}
                </span>
            </template>
        </div>
    </td>
</template>
<script>
    export default {
        name: "ImageCell",
        data() {
            var fields = this.format.split(",");
            return {
                displayField: fields[0],
                imageField: fields[1],
                id: fields[2],
            };
        },
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        data() {
            var fields = this.format.split(",");
            return {
                displayField: fields[0],
                imageField: fields[1],
                id: fields[2],
            };
        },
        computed: {
            displayValue() {
                return this.dataItem[this.displayField];
            },
            imageValue() {
                return this.dataItem[this.imageField];
            },
        },
        methods: {
            clickHandler() {
                if (this.editor !== undefined) {
                    this.$emit(this.editor, this.dataItem);
                }
            }
        },
        filters: {
            nameInitials: function (value) {
                var names = value.split(" "),
                    initials = names[0].substring(0, 1).toUpperCase();
                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
            }
        }
    };
</script>
<style scoped>
    .circleShape {
        min-width: 50px;
        min-height: 50px;
    }
    .circleShape img {
        border-radius: 50%;
    }

    .gridLink {
        color: #002B49;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #002B49;
        text-shadow: 0 0 1px #40C1AC;
        display: inline-flex;
        align-items: center;
    }

        .gridLink:hover {
            color: #29897d;
        }
</style>